import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Route } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../app.config';
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../services/user.service';
import { User } from '../models/user';

import { AuthenticationService } from '../services/authentication.service';
import { BuildingService } from '../services/building.service';
import { NavigationService } from '../services/navigation.service';
import { AlertService } from '../services/alert.service';
import { AlertLevel, AlertStatus, MaintenanceLevel, MessageLevel, StorageFields } from '../common/enums';
import { DashboardService } from '../services/dashboard.service';
import { colorutils } from '../common/colorutils';
import { CriticalService } from '../services/critical.service';
import { CommonService } from '../services/common.service';
import { SettingsService } from '../services/settings.service';
import { TranslationService } from '../services/translation.service';
import { navutils } from '../common/navutils';
import { logicutils } from '../common/logicutils';
import { StorageService } from '../services/storage.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './layout.component.html',
  styleUrls: ["./layout.component.scss"]
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscription: Subscription;
  private _routerSub = Subscription.EMPTY;
  public flag_url: string = "./assets/img/flags/en.png";
  public lang: string = "EN";
  private logo = "./assets/img/layout/logo.png";
  public navItems;
  public is_loaded: boolean;
  public hide_titlebar: boolean = false;
  public sidebarMinimized = true;
  public element: HTMLElement = document.body;
  public appVersion: String;

  public rights: object;

  private current_route: string;
  private current_route_generic: string;
  public currentUser: User;
  private current_user_id: string

  public alerts: number = 0;
  public alert_color: string = "#ffa027"
  public maintenances: number = 0;
  public maintenance_color: string = "#ffa027"
  public technicals: number = 0;
  public technical_color: string = "#ffa027"
  public criticals: number = 0;
  public critical_color: string = "#ffa027"
  public surveys: number = 0;
  public surveys_color: string = "#ffa027"

  constructor(private route: ActivatedRoute, private router: Router, private auth: AuthenticationService,
    public translate: TranslateService,
    public translationService: TranslationService,
    private builidingService: BuildingService,
    private navigationService: NavigationService,
    private userService: UserService,
    private alert_service: AlertService,
    private dashboard_service: DashboardService,
    private critical_service: CriticalService,
    private common_service: CommonService,
    private settingsService: SettingsService,
    private storage_service: StorageService,
    public dialog: MatDialog
  ) {
    try {
      if (AppConfig.company_settings.info.logo) {
        this.logo = AppConfig.company_settings.info.logo;
      }
    } catch (e) {
    }
    // this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

    this._routerSub = this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        let start_time = Date.now();
        await this.addNavigation(event);
        // hide the title bar for the collect page and the analysis page
        this.hide_titlebar = event.url.startsWith("/wizards/collect-fingerprint") || event.url.startsWith("/supervision/analysis");
        // fills the side nav
        if (event.url != '/login' && event.url != '/forgot-password')
          this.createNavItemsList();
        // add notification count to alert bell
        if (this.rights["right_rules"])
          await this.updateAlerts();
        if (this.rights["right_maintenance"])
          await this.updateMaintennances();
        if (this.rights["right_supervisor"]) {
          //await this.updateTechnicals();
          await this.updateCriticals();
          await this.updateSurvey();
        }
        logicutils.logDuration("Fill Navigation Menu", start_time);
      }
    });

    const current_lang = this.storage_service.getValue(StorageFields.CurrentLang);//localStorage.getItem("current_lang")
    const browserLang: string = translate.getBrowserLang();
    if (!translate.currentLang) {
      let new_lang = current_lang ? current_lang : browserLang.match(logicutils.AllowedLangs) ? browserLang : "en";
      this.ChangeLanguage(new_lang)
    } else {
      this.lang = translate.currentLang.toUpperCase();
      this.flag_url = "./assets/img/flags/" + translate.currentLang + ".png";
    }

    this.appVersion = AppConfig.version;
  }

  ngOnInit() {
    this.currentUser = { username: '', password: '', mail: '', is_installer: false }
    this.current_user_id = this.storage_service.getValue(StorageFields.CurrentUserId);//localStorage.getItem('currentUserId');
    if (this.current_user_id) {
      this.userService.getUser(this.current_user_id).pipe().subscribe((user) => {
        this.currentUser = user;
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    if (this._routerSub) {
      this._routerSub.unsubscribe();
      this._routerSub = null;
    }
  }

  private async updateAlerts() {
    let records = await this.alert_service.getAlerts().toPromise();
    let level: AlertLevel = AlertLevel.Info;
    let status: AlertStatus = AlertStatus.Closed;
    for (var alert of records)
      if (level < alert.level)
        level = alert.level;
    this.alerts = records.filter(m => m.level == level && m.status != status).length;
    this.alert_color = colorutils.getAlertColor(level);
  }

  private async updateMaintennances() {
    let maintenances = await this.dashboard_service.getMaintenances(true).toPromise();
    let records = maintenances.equipment_alerts;
    let level: MaintenanceLevel = MaintenanceLevel.UpToDate;
    for (var alert of records)
      if (level < alert.level)
        level = alert.level;
    this.maintenances = records.filter(m => m.level == level).length;
    this.maintenance_color = colorutils.getMaintenanceColor(level);
  }

  private async updateCriticals() {
    let records = await this.critical_service.getCriticals().toPromise();
    let version = AppConfig.version;
    let level: boolean = false;
    let criticals = records.filter(c => !c.submitted);
    for (var critical of criticals)
      if (level < critical.fatal)
        level = critical.fatal;
    this.criticals = criticals.filter(m => m.fatal == level).length;
    this.critical_color = colorutils.getCriticalColor(criticals.length, level);
  }

  private async updateSurvey() {
    let records = await this.common_service.getCollection("__info", "{}").toPromise();
    let info = await this.settingsService.getFingerPrintReferenceInfo().toPromise();
    let ok_fprt: boolean = false;
    let ok_survey: boolean = false;
    if (records && records.length == 1) {
      let record = records[0];
      if ("fprt_reference" in record) {
        let reference = record["fprt_reference"];
        if (record["FPRT"] == reference["FPRT"] && record["RSMF"] == reference["RSMF"] && record["RSSI"] == reference["RSSI"] && record["SURV"] == reference["SURV"])
          ok_fprt = true;
      }
      if ("survey" in record) {
        let survey = record["survey"];
        if (record["FPRT"] == survey["FPRT"] && record["RSMF"] == survey["RSMF"] && record["RSSI"] == survey["RSSI"] && record["SURV"] == survey["SURV"]
          && survey["fprt_count"] == info["count"] && survey["fprt_date"] == info["last_date"])
          ok_survey = true;
      }
    }
    this.surveys = 1;
    this.surveys_color = colorutils.getMessageColor(ok_fprt && ok_survey ? MessageLevel.Success : MessageLevel.Error);
  }

  private async addNavigation(event: NavigationEnd) {
    let url = event.url;
    let url_paths = url.split("/");
    let paths = [];
    let cfg = this.router.config.find(c => this.pathMatch(c, url_paths.filter(p => p)));
    if (cfg) {
      // if we are on login or forgot password, we use it from router config
      paths = url_paths;
    }
    else if (url == "" || url == "/") {
      // if we come from home we directly take the redirectTo
      let config = this.router.config.find(c => c.redirectTo != null);
      paths = config ? config.redirectTo.split("/") : ["home"];
    } else {
      // find the matching route in the routing
      paths = this.getMatchingRoute(this.route.routeConfig, url_paths, []);
    }
    // to allow mark list menu selected also for the forms
    if (paths[paths.length - 1] == "upd")
      this.current_route_generic = paths.join("/");
    await this.navigationService.addOrUpdNavigation(this.current_user_id, paths.filter(p => p).join("_"));
  }

  /**
   * Gets the route matching with the given path excluding the parameters
   */
  private getMatchingRoute(route: Route, paths: string[], matching: string[]) {

    if (route) {
      // if we are there the paths matches, so we extract the next path
      let route_paths = this.getPathArray(route);
      let new_paths = paths.slice(route_paths.length);
      matching = matching.concat(route_paths)

      // continue iteration with matching child
      let child = this.getChildren(route).find(c => this.pathMatch(c, new_paths));
      return this.getMatchingRoute(child, new_paths, matching)
    }
    // we reached the end of the path, so the matching is done
    return matching;
  }

  /**
   * Gets the children routes of the current route including the lazy loaded sub routes
   */
  private getChildren(route: Route) {
    let children = route.children ? route.children : [];
    let routerConfig = /*<LoadedRouterConfig>*/(<any>route)['_loadedConfig'];
    let config = routerConfig ? routerConfig.routes : [];
    return children.concat(config);
  }

  /**
   * Gets an array of all componeents of a route path excluding the parameters
   */
  private getPathArray(route: Route) {
    let route_path = route.path;
    // eliminate the parameters
    if (route_path.includes("/:"))
      route_path = route_path.substr(0, route_path.indexOf("/:"));
    return route_path.split("/");
  }

  /**
   * Gets if the route path is a component of the specified path
   */
  private pathMatch(route: Route, paths: string[]) {
    let route_paths = this.getPathArray(route);
    if (paths.length < route_paths.length)
      return false;
    for (var i = 0; i < route_paths.length; i++)
      if (route_paths[i] != paths[i])
        return false;
    return true;
  }

  public onNavigateHome() {
    this.router.navigate(['/']);
  }

  ngAfterViewInit() {
  }


  private substrNavMenuTitle(str, length) {
    return str.length > length ? str.substring(0, length) + "..." : str;
  }

  private createNavItemsList() {
    this.is_loaded = true;
    this.rights = this.auth.getUserRights();
    this.current_route = this.router.url;
    let items: Array<any> = [];

    if (AppConfig.front_settings && AppConfig.front_settings.views.show_map)
      items.push(this.getMenu('Map', 'fa-map-marker', '/dashboard/map', false, true));

    items.push(this.getMenu('Navigation', 'fa-map-marker', '/dashboard/find', false, true));

    // gets all the buildings //logo carte?
    this.subscription = this.builidingService.getBuildingMenu().subscribe((buildings) => {
      buildings.unshift({
        id: 0,
        name: AppConfig.company_settings.info.name ? AppConfig.company_settings.info.name : "Site"
      });
      for (var building of buildings) {
        let building_name = this.substrNavMenuTitle(building.name, 20);
        let building_menu = this.getMenu(building_name, 'fa-building', null, true);
        if (this.rights['right_inventory']) {
          this.AddMenu(building_menu, "Inventory", '../../assets/img/layout/menu-buildings.png', navutils.GetEventsListPath(building.id, null, null, null, null, null, null, null, null), false, true);
        }
        // dashboards (only one as the navigation is done in the dashboard itself)
        if (this.rights['right_statistics'] && AppConfig.front_settings && AppConfig.front_settings.views.show_dashboard)
          this.AddMenu(building_menu, "Dashboard", '../../assets/img/layout/menu-dashboard.png', navutils.GetDashboardPath(building.id, null, null), false, true);
        else if (this.rights['right_maintenance'])
          this.AddMenu(building_menu, 'Dashboard', '../../assets/img/layout/menu-dashboard.png', navutils.GetDashboardMaintenancePath(building.id, null), false, true);
        else if (this.rights['right_statistics'] && !building.id)
          this.AddMenu(building_menu, "Dashboard", '../../assets/img/layout/menu-dashboard.png', '/dashboard/dashboard-battery', false, true)

        items.push(building_menu);
      }

      let homecare_menu = this.getMenu("Homecare", "fa-cogs", null, true, true);
      if (this.rights['right_equipments']) {
        this.AddMenu(homecare_menu, "Homecare", '../../assets/img/layout/menu-consumers.png', '/forms/consumers/list', false, true);
        this.AddMenu(homecare_menu, "Dashboard", "../../assets/img/layout/menu-dashboard.png", '/dashboard/dashboard-consumer/', false, true);
      }
      if (homecare_menu.children.length > 0)
        items.push(homecare_menu);

      let admin_menu = this.getMenu("Administration", 'fa-cogs', null, true, true);
      if (this.rights['right_sites']) {
        this.AddMenu(admin_menu, "Districts", '../../assets/img/layout/menu-districts.png', '/forms/districts/list', false, true);
        this.AddMenu(admin_menu, "Buildings", '../../assets/img/layout/menu-buildings.png', '/forms/buildings/list', false, true);
        this.AddMenu(admin_menu, "$Floors$", '../../assets/img/layout/menu-floors.png', '/forms/floors/list', false, true);
      }
      if (this.rights['right_equipments']) {
        this.AddMenu(admin_menu, "$Equipment familys$", '../../assets/img/layout/menu-equipments.png', '/forms/equipment-families/list', false, true);
        this.AddMenu(admin_menu, "$Equipment kinds$", '../../assets/img/layout/menu-equipmentskinds.png', '/forms/equipment-kinds/list', false, true);
        this.AddMenu(admin_menu, "$Equipments$", '../../assets/img/layout/menu-equipments.png', '/forms/equipments/list', false, true);
      }
      if (this.rights['right_devices']) {
        this.AddMenu(admin_menu, "$Gateways$", '../../assets/img/layout/menu-gateways.png', '/forms/gateways/list', false, true);
        this.AddMenu(admin_menu, "Sensors", '../../assets/img/layout/menu-sensors.png', '/forms/sensors/list', false, true);
      }
      if (this.rights['right_users']) {
        this.AddMenu(admin_menu, "Roles", '../../assets/img/layout/menu-roles.png', '/forms/roles/list', false, true);
        this.AddMenu(admin_menu, "Users", '../../assets/img/layout/menu-users.png', '/forms/users/list', false, true);
      }
      if (this.rights['right_export']) {
        this.AddMenu(admin_menu, "Exports", '../../assets/img/layout/menu-exports.png', '/wizards/user-export', false, true);
      }
      if (this.rights['right_api'])
        this.AddMenu(admin_menu, "API", '../../assets/img/layout/menu-API.png', '/administration/api-doc', false, true);

      if (this.rights['right_rules']) {
        this.AddMenu(admin_menu, "Rules", '../../assets/img/layout/menu-rules.png', '/administration/rules/list', false, true);
        this.AddMenu(admin_menu, "Alerts", '../../assets/img/layout/bell.png', '/administration/alerts/list', false, true);
      }
      if (this.rights['right_maintenance'])
        this.AddMenu(admin_menu, "Maintenances", '../../assets/img/layout/menu-maintenance.png', navutils.GetMaintenanceListPath(null, null)  /*'/administration/maintenances/list'*/, false, true);
      if (admin_menu.children.length > 0)
        items.push(admin_menu);

      let supervision_menu = this.getMenu("Supervision", 'fa-cogs', "/supervision/supervision", true, true);
      if (this.rights['right_supervisor']) {
        this.AddMenu(supervision_menu, "Settings", '../../assets/img/layout/menu-equipmentskinds.png', '/supervision/settings/upd', false, true);
        this.AddMenu(supervision_menu, "Supervision", '../../assets/img/layout/technical.png', '/supervision/supervision', false, true);
        this.AddMenu(supervision_menu, "Exports", '../../assets/img/layout/menu-exports.png', '/supervision/exports', false, true);
        this.AddMenu(supervision_menu, "Usages", '../../assets/img/layout/menu-niveau.png', '/supervision/usages', false, true);
        this.AddMenu(supervision_menu, "Equipment usage", '../../assets/img/layout/menu-niveau.png', '/supervision/equipment-usages', false, true);
        this.AddMenu(supervision_menu, "Variations", '../../assets/img/layout/menu-niveau.png', '/supervision/variations/list', false, true);
        this.AddMenu(supervision_menu, "Durations", '../../assets/img/layout/duration.png', '/supervision/durations/list', false, true);
        this.AddMenu(supervision_menu, "Missing", '../../assets/img/layout/missing.png', '/supervision/missings/list', false, true);
        this.AddMenu(supervision_menu, "Technical", '../../assets/img/layout/technical.png', '/supervision/technicals/list', false, true);
        this.AddMenu(supervision_menu, "Critical", '../../assets/img/layout/bug.png', '/supervision/criticals/list', false, true);
        this.AddMenu(supervision_menu, "Diagnostic", '../../assets/img/layout/technical.png', '/supervision/diagnostic', false, true);
        this.AddMenu(supervision_menu, "Duplicator", '../../assets/img/layout/duplicator.png', '/supervision/duplicator', false, true);
      }
      if (supervision_menu.children.length > 0)
        items.push(supervision_menu);

      this.navItems = items;

      // collapse the menu for the main page
      if (this.router.url == "/workflow/work-buildings" || this.router.url == "/workflow/work-buildings")
        this.showSidenav(false);
    });
  }

  private AddMenu(main_menu: any, name: string, icon: string, url: string, has_children: boolean, force_menu: boolean = false) {
    let sub_menu = this.getMenu(name, icon, url, has_children, force_menu);
    if (sub_menu.selected)
      main_menu.expanded = true;
    main_menu.children.push(sub_menu);
  }

  private getMenu(name: string, icon: string, url: string, has_children: boolean, translate: boolean = false): any {
    // mark list menu selected also for the forms
    let selected: boolean = (url == this.current_route) || (url && url.endsWith("/list") && url.substring(0, url.indexOf("/list")) + "/upd" == this.current_route_generic);
    let expanded = selected;
    let children = has_children ? [] : undefined;
    //if (selected && force_menu)
    //  this.showSidenav(false);
    return { name: name, url: url, icon: icon, selected: selected, expanded: expanded, children: children, translate: translate };
  }

  onNavigate(url: string) {
    if (url)
      this.router.navigate([url]);
  }

  onMenuDropDownToggle(event) {
    let element = event.srcElement;
    if (element.tagName.toLowerCase() == "i" || element.tagName.toLowerCase() == "span")
      element = element.parentElement;
    element.lastChild.classList.toggle("fa-caret-up");
    element.lastChild.classList.toggle("fa-caret-down");
    element.classList.toggle("active");
    var dropdownContent = element.nextElementSibling;

    if (dropdownContent.style.display === "block")
      dropdownContent.style.display = "none";
    else
      dropdownContent.style.display = "block";
  }

  toggleSidenav() {
    let sidebar = document.getElementById("sidebar");
    this.showSidenav((sidebar.style.width == "0px"));
  }

  showSidenav(show: boolean) {
    if (!this.rights['right_users'])
      show = false;
    let sidebar = document.getElementById("sidebar");
    let main = document.getElementById("main");
    let page_title = document.getElementById("page_title");
    let version_label = document.getElementById("version_label");

    let size = show ? "220px" : "0px";
    let display = show ? "block" : "none";
    sidebar.style.width = size;
    version_label.style.width = size;
    version_label.style.display = display;

    main.style.marginLeft = size;
    if (page_title)
      page_title.style.width = "calc(100% - " + size + ")";

    // main_container exists only in location and historic view
    let element = document.getElementById("main_container");
    var event = window.document.createEvent('UIEvents');
    event.initEvent('resize', true, false);
    // first dispatch event immediatly
    // need to delay to ensure new widths are setted before the target receives the event
    if (element) {
      element.dispatchEvent(event);
      setTimeout(() => element.dispatchEvent(event), 200);
    }
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
    this.flag_url = "./assets/img/flags/" + language + ".png";
    this.lang = language.toUpperCase();
    this.storage_service.setValue(StorageFields.CurrentLang, language);//localStorage.setItem('current_lang', language);
  }

  public navigateHome() {
    this.router.navigate(['/']);
  }

  /**
   * Shows the user profile informations
   * */
  public navigateProfile() {
    this.router.navigate(['/authentication/profile']);
  }

  public navigateInformation() {
    this.router.navigate(['/workflow/work-information']);
  }

  public navigateAlerts() {
    this.router.navigate(['/administration/alerts/list']);
  }

  public navigateMaintenances() {
    navutils.NavigateToMaintenanceList(this.router, null, null);
  }

  public navigateTechnicals() {
    this.router.navigate(['/supervision/technicals/list']);
  }

  public navigateCriticals() {
    this.router.navigate(['/supervision/criticals/list']);
  }

  public navigateSupervision() {
    this.router.navigate(["/supervision/supervision"]);
  }

  public navigateDiagnostic() {
    this.router.navigate(['/supervision/diagnostic']);
  }

  public async logout() {
    let isConfirmed = await navutils.openDialogConfirm(this.dialog, "Deconnection", "Do you really want to leave this page ?");
    if (isConfirmed) {
      this.auth.logout();
      this.router.navigate(['/login']);
    }
  }
}