import { mathutils } from "../../mathutils";
import { PointFloor } from "./PointFloor";


export class Point2D {

  public x: number;
  public y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public static FromJson(json: any) {
    return new Point2D(json.x, json.y);
  }

  public static FromArray(array: number[]) {
    return new Point2D(array[0], array[1]);
  }

  public static FromPoint3D(point: PointFloor): Point2D {
    return new Point2D(point.x, point.y);
  }

  public IsEqual(other: Point2D): boolean {
    return this.x == other.x && this.y == other.y;
  }

  public ToString(): string {
    return this.x + "," + this.y;
  }

  public ToArray(): number[] {
    return [this.x, this.y];
  }

  public ToJson(): any {
    return { x: this.x, y: this.y };
  }

  public GetDistance(position: Point2D): number {
    return Math.hypot(position.x - this.x, position.y - this.y);
  }

  public GetAngle(position: Point2D): number {
    return Math.atan2(position.y - this.y, position.x - this.x);
  }

  public Substract(origin: Point2D) {
    return new Point2D(this.x - origin.x, this.y - origin.y);
  }

  public Add(origin: Point2D) {
    return new Point2D(this.x + origin.x, this.y + origin.y);
  }

  public Shift(x: number, y: number) {
    return new Point2D(this.x + x, this.y + y);
  }

  public Scale(ratio: number) {
    return new Point2D(this.x * ratio, this.y * ratio);
  }

  public GetAngularPoint(): AngularPoint {
    var distance = Math.hypot(this.x, this.y);
    var angle = mathutils.LimitAngle(Math.atan2(this.y, this.x));
    return new AngularPoint(angle, distance);
  }

  //public Round(step: number): Point2D {
  //  return new Point2D(mathutils.RoundStep(this.x, step), mathutils.RoundStep(this.y, step));
  //}

  public static GetPoint(origin: Point2D, angle: number, distance: number): Point2D {
    return origin.Add(new AngularPoint(angle, distance).GetCartesianPoint());
  }

  public static GetPolygon(coordinates: number[][]) {
    let pts: Point2D[] = [];
    for (let coordinate of coordinates)
      pts.push(new Point2D(coordinate[0], coordinate[1]));
    return pts;
  }
}

export class AngularPoint {

  public angle: number;
  public distance: number;

  constructor(angle: number, distance: number) {
    this.angle = angle;
    this.distance = distance;
  }

  public GetCartesianPoint(): Point2D {
    return new Point2D((Math.cos(this.angle) * this.distance), (Math.sin(this.angle) * this.distance));
  }

  public GetCartesianPoint3D(z: string): PointFloor {
    return new PointFloor((Math.cos(this.angle) * this.distance), (Math.sin(this.angle) * this.distance), z);
  }
}
