import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FindResult } from '../../../common/plan/common/FindResult';
import { NavIcon } from '../../../common/plan/common/NavIcon';
import { PathVector } from '../../../common/plan/common/PathVector';
import { PlanComponent } from '../../../common/plan/plan.component';
import { FindService } from '../../../services/find.service';
import { FloorService } from '../../../services/floor.service';
import { RoomService } from '../../../services/room.service';
import { SettingsService } from '../../../services/settings.service';
import { DisplayBase } from './DisplayBase';
import { PathDisplay } from './PathDisplay';
import { PerspectiveDisplay } from './PerspectiveDisplay';
import { PlanDisplay } from './PlanDisplay';


@Component({
  selector: 'app-find',
  templateUrl: './find.component.html'
})
export class FindComponent implements OnInit, AfterViewInit {

  @ViewChild("plan2", { static: true }) public plan2!: PlanComponent;
  @ViewChild("plan", { static: true }) public plan!: PlanComponent;
  @ViewChild("plan3", { static: true }) public plan3!: PlanComponent;

  @ViewChild("pathview", { static: true }) public pathview!: ElementRef<HTMLDivElement>;
  @ViewChild("walkview", { static: true }) public walkview!: ElementRef<HTMLDivElement>;
  @ViewChild("planview", { static: true }) public planview!: ElementRef<HTMLDivElement>;
  @ViewChild("roadview", { static: true }) public roadview!: ElementRef<HTMLDivElement>;

  protected floors: any[];
  protected rooms: any[];

  protected selected_buildings: any[];
  protected selected_floors: any[];

  protected current_building_id: string;
  protected current_floor_id: string;

  private displays: DisplayBase[] = [];

  protected route_message: any[];

  protected find_mode: boolean;

  protected spots: any[];

  protected selected_spots: any[];

  protected current_spot_index: number;
  protected start_poi: any | null = null;
  protected target_poi: any | null = null;

  private result: FindResult | null;
  protected current_point_index: number = 0;
  protected label: string = "-";

  protected message: string;

  public static Corridor: number = -1;

  constructor(
    private findService: FindService,
    private floorService: FloorService,
    private roomService: RoomService,
    private settingsService: SettingsService,
  ) {
  }

  public async ngOnInit(): Promise<void> {
  }

  public async ngAfterViewInit(): Promise<void> {
    try {
      await this.Initialize();
    } catch (e) {
      console.error(e);
    }
  }

  private async Initialize() {
    // determinates the type number of the corridors (if exists)
    let settings = await lastValueFrom(this.settingsService.getSetting("Front Settings"));
    let types = settings.data.room_types
    for (let key of Object.keys(types)) {
      if (types[key] == "Couloir") {
        FindComponent.Corridor = parseInt(key[4]);
        break;
      }
    }

    this.floors = await lastValueFrom(this.floorService.getFloorsWithImage());
    this.rooms = await lastValueFrom(this.roomService.getRooms());
    await this.plan2.Initialize(null, 400, 400, null);
    //await this.plan4.Initialize(null, 400, 400, null);
    await this.plan.Initialize(null, 400, 400, null);
    await this.plan3.Initialize(null, 400, 400, null);

    let result = await this.findService.GetSpots()
    this.selected_buildings = result.buildings;
    this.spots = result.spots;
    this.ChangeSelection(null, null);
  }

  protected OnBuildingChanged() {
    this.selected_floors = this.floors.filter(f => f.building_data._id == this.current_building_id);
    this.current_floor_id = null;
    this.OnFloorChanged();
  }

  protected OnFloorChanged() {
    this.ChangeSelection(this.current_building_id || null, this.current_floor_id || null)
  }

  public ChangeSelection(building_id: string, floor_id: string) {
    this.selected_spots = this.spots.filter(s => !building_id || s.building_id == building_id).filter(s => !floor_id || s.floor_id == floor_id);
  }

  protected async OnFind() {
    this.find_mode = true;
    this.message = null;
    for (let display of this.displays)
      display.Clear();
    this.displays = [];
    this.route_message = [];
    this.current_point_index = 0;

    if (!this.start_poi || !this.target_poi)
      return;

    this.result = await this.findService.FindSpot(this.start_poi._id, this.target_poi._id);

    if (!this.result)
      return;
    this.message = this.result.Message;
    this.route_message = this.result.Entries.map<any>(e => { return { message: e.message, icon: this.GetIcon(e.icon) } });

    this.displays.push(new PathDisplay(this.result, this.plan2, this.floors, this.rooms));
    //this.displays.push(new PathPerspectiveDisplay(this.result, this.plan4, this.floors, this.rooms));
    this.displays.push(new PerspectiveDisplay(this.result, this.plan, this.floors, this.rooms));
    this.displays.push(new PlanDisplay(this.result, this.plan3, this.floors, this.rooms));
    this.Redraw();
  }

  private GetIcon(icon: NavIcon) {
    if (icon == NavIcon.Straight)
      return "arrow-up";
    if (icon == NavIcon.Right)
      return "arrow-right";
    if (icon == NavIcon.Back)
      return "arrow-down";
    if (icon == NavIcon.Left)
      return "arrow-left";
    if (icon == NavIcon.Target)
      return "flag-checkered";
    if (icon == NavIcon.Stair)
      return "bars";
    if (icon == NavIcon.Lift)
      return "sort";
    return "question";
  }

  protected OnResetFind() {
    this.find_mode = false;
    this.message = null;
  }

  public OnChangePoi(id: number) {
    this.current_spot_index = id;
    if (!this.start_poi)
      this.start_poi = this.selected_spots[id];
    else
      this.target_poi = this.selected_spots[id];
  }

  protected OnChangePoint(id: number) {
    this.current_point_index = id;
    this.Redraw();
  }

  protected OnPrevPoint() {
    if (this.current_point_index <= 0)
      this.current_point_index = 0;
    else
      this.current_point_index--;
    this.Redraw();
  }

  protected OnNextPoint() {
    if (this.current_point_index >= this.route_message.length - 1)
      this.current_point_index = this.route_message.length - 1;
    else
      this.current_point_index++;
    this.Redraw();
  }

  private async Redraw() {
    if (!this.result)
      return;
    let entry: PathVector = this.result.Entries[this.current_point_index];
    let floor_id: string = entry.start.floor_id;
    let floor = this.floors.find(f => f._id == floor_id);
    this.label = floor ? floor.building_data?.name + " - " + floor.name : "";
    for (let display of this.displays)
      await display.Draw(this.current_point_index);
    this.DrawViews();
  }

  private DrawViews() {
    this.pathview.nativeElement.innerHTML = this.result.PathViews[this.current_point_index];
    this.walkview.nativeElement.innerHTML = this.result.WalkViews[this.current_point_index];
    this.planview.nativeElement.innerHTML = this.result.PlanViews[this.current_point_index];
    //this.roadview.nativeElement.innerHTML = this.result.RoadViews[this.current_point_index];
  }
}
