import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { FieldType, FieldConfig, StatusBehaviours } from '../models/common';
import { ExportKind } from '../models/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../services/translation.service';
import { TokenService } from '../services/token.service';
import streamSaver from 'streamsaver';
import fetchStream from 'fetch-readablestream';
import * as ponyfill from 'web-streams-polyfill/ponyfill'
import { AppConfig } from '../app.config';
import { ErrorLevel, RedirectKind } from './enums';

import { navutils } from './navutils';


export class logicutils {

  /**
   * Checked if a value is null or "0"
   * @param value { any } the value to checked
   * @returns a boolean according with the cheched
   */
  public static isNull(value: any): boolean {
    return !value || (value == "0");
  }

  /**
   * Convert the first character of a string to lowercase
   * @param text { string } the text to convert
   * @returns the text passed as a parameter with the first character converts to lowercase
   */
  public static firstToLower(text: string): string {
    if (text.length == 0)
      return text;
    return text[0].toLowerCase() + text.substring(1)
  }

  /**
   * Convert the first character of a string to uppercase
   * @param text { string } the text to convert
   * @returns the text passed as a parameter with the first character converts to uppercase
   */
  public static firstToUpper(text: string): string {
    if (text.length == 0)
      return text;
    return text[0].toUpperCase() + text.substring(1)
  }

  /**
   * limits the size of a text with a maximum number of characters
   * @param text { string } the text to limit
   * @param max { number } the maximum number of characters
   * @returns the limited text string
   */
  public static limitText(text: string, max: number) {
    if (!text)
      return "";
    return text.substring(0, max) + (text.length > max ? "..." : "");
  }

  /**
   * Check if a name match with the required criterias
   * @param search { string } the name to checked
   * @param candidate { string } the required criterias
   * @returns the boolean according with the check
   */
  public static matchName(search, candidate) {
    return logicutils.removeSpecialChars(search) == logicutils.removeSpecialChars(candidate);
  }

  /**
   * Remove all the special characters of a string
   * @param value { string } the string to remove special characters
   * @returns the string without special characters
   */
  public static removeSpecialChars(value) {
    return value.replace(/(?!\w|\s)./g, '')
      .replace(/\s+/g, ' ')
      .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').toLowerCase();
  }

  /**
   * Copies the content of an input in the clipboard
   * @param input { any } the input to copy the content
   * @param document { Document } the current page
   */
  public static copyInput(document: Document, input: any) {
    input.disabled = false;
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
    input.disabled = true;
  }

  /**
   * Copies the content of an element in the clipboard
   * @param document { Document } the current page
   * @param element { any } the element to copy the content
   */
  public static copyElement(document: Document, element) {
    let range;
    let selection;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      selection = window.getSelection();
      selection.removeAllRanges();

      range.selectNode(element);
      selection.addRange(range);
    }

    document.execCommand("Copy");
    selection.removeAllRanges();
  }

  /**
   * Uploads an image without cheking size or dimensions
   * @param event the event containing the uploaded image
   * @param compoenent the component containing a 'setImage' method to set resized image
   */
  public static async uploadImage(event: any, compoenent) {
    var reader = new FileReader();
    reader.onload = function (event: any) {
      compoenent.setImage(btoa(event.target.result));
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  /**
   * Checks the size of the image uploaded in event and call the setImage method on the given compoenent
   * The componenent must have a 'setImage' method and a 'dialog' field
   * @param event the event containing the uploaded image
   * @param compoenent the component containing a 'setImage' method to set resized image and a 'dialog' field
   * @param navutils navutils must be passed to avoid circular references in logicutils
   * @param max_size the max size of the image in byte
   */
  public static async uploadImageAndCheckSize(event: any, compoenent, max_size = 672874) {
    let file = event.target.files[0];
    if (file.size > max_size) {
      let message = 'The image is too big\r\n';
      message += parseInt(file.size / 1024 + "") + " KB\r\n";
      message += "exceed\r\n";
      message += parseInt(max_size / 1024 + "") + " KB";
      await navutils.openDialogMessage(compoenent.dialog, "Uploading Image", message);
      return;
    }
    var reader = new FileReader();
    reader.onload = function (event: any) {
      compoenent.setImage(btoa(event.target.result));
    };
    reader.readAsBinaryString(file);
  }

  /**
   * Resizes the image uploaded in event and call the setImage method on the given compoenent
   * @param event the event containing the uploaded image
   * @param compoenent the component containing a 'setImage' method to set resized image
   * @param max_width the max width of the target image
   * @param max_height the max height of the target image
   */
  public static uploadImageAndResize(event: any, compoenent, max_width, max_height) {
    const reader = new FileReader();
    reader.onload = function (event: any) {
      logicutils.resizeImage(event, compoenent, max_width, max_height);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  private static resizeImage(event: any, component, max_width, max_height) {
    var img = document.createElement("img");
    img.src = event.target.result;
    img.onload = function () {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);
      var width = img.width;
      var height = img.height;
      if (width > max_width) {
        height *= max_width / width;
        width = max_width;
      }
      if (height > max_height) {
        width *= max_height / height;
        height = max_height;
      }
      canvas.width = width;
      canvas.height = height;
      var context = canvas.getContext("2d");
      context.drawImage(img, 0, 0, width, height);
      let data_url = canvas.toDataURL("image/png");
      component.setImage(data_url.replace("data:image/png;base64,", ""));
    };
  }

  /**
   * Checks the dimensions of the image uploaded in event and call the setImage method on the given compoenent
   * @param event the event containing the uploaded image
   * @param compoenent the component containing a 'setImage' method to set resized image and a 'dialog' field
   * @param navutils navutils must be passed to avoid circular references in logicutils
   * @param required_width the required width of the uploaded image
   * @param required_height the required height of the uploaded image
   */
  public static setImageAndCheckDimensions(event: any, component, required_width, required_height) {
    const reader = new FileReader();
    reader.onload = function (event: any) {
      logicutils.checkImageDimensions(event, component, required_width, required_height);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  private static checkImageDimensions(event: any, component, required_width, required_height) {
    var img = document.createElement("img");
    img.src = event.target.result;
    img.onload = async function () {
      var width = img.width;
      var height = img.height;
      if (width != required_width || height != required_height) {
        let message = "The dimensions dont' match\r\n";
        message += width + " x " + height + " px\r\n";
        message += "instead of\r\n";
        message += required_width + " x " + required_height + " px";
        await navutils.openDialogMessage(component.dialog, "Uploading Image", message);
        return;
      }
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      let dataurl = canvas.toDataURL("image/png");
      component.setImage(dataurl.replace("data:image/png;base64,", ""));
    }
  }

  /**
   * Gets the keys of an enum
   * @param enumeration {any[]} the enum that contains the required keys
   * @returns an array containing the keys of the enum
   */
  public static getEnumKeys(enumeration: any): any[] {
    return Object.keys(enumeration).filter(k => !isNaN(Number(k))).map(v => Number(v));
  }

  /**
   * Gets the keys of an enum
   * @param enumeration {any[]} the enum that contains the required keys
   * @returns an array containing the keys of the enum
   */
  public static getEnumKeysString(enumeration: any): any[] {
    return Object.keys(enumeration);
  }

  /**
   * Get the names with the associed keys of an enum
   * @param enumeration {any[]} the enum that contains the required keys
   * @param text_enumeration {any[]} the enum that contains the required names
   * @returns an array of keys values () key: 0, name: "First")
   */
  public static getEnumNames(enumeration: any, text_enumeration: any, translate: TranslationService = null): any[] {
    return Object.keys(enumeration).filter(e => !isNaN(Number(e)) && !!text_enumeration[enumeration[e]]).map(o => {
      return { key: Number(o), name: translate ? translate.translate(text_enumeration[enumeration[o]]) : text_enumeration[enumeration[o]] };
    });
  }

  /**
 * Get the names with the associed keys of an enum
 * the format is { key: 0, name: "First"}
 * @param enumeration {any[]} the enum that contains the desired keys
 * @param text_enumeration {any[]} the enum that contains the desired names
 * @param translate {TranslateService} an instance of TranslateService
 * @returns the enum names in string type to use generete selects
 */
  public static getEnumNamesString(enumeration: any, text_enumeration: any, translate: TranslationService = null): any[] {
    return Object.keys(enumeration).filter(e => !!text_enumeration[enumeration[e]]).map(o => {
      return { key: o, name: translate ? translate.translate(text_enumeration[enumeration[o]]) : text_enumeration[enumeration[o]] };
    });
  }

  /**
  * gets the string label for a specific key
  * @param enumeration main enum
  * @param text_enumeration corresponding enum with string values
  * @param key the provided enum key
  * @returns the string value matching the specified key
  */
  public static getEnumLabel(enumeration: any, text_enumeration: any, key: any) {
    const objects = this.getEnumNames(enumeration, text_enumeration);
    return objects.filter(obj => obj.key == key)[0]?.name
  }

  /**
   * Gets the field type according with the type of parameter value
   * @param value {any} value type check
   * @returns the field type according with the type of parameter value
   */
  public static getFieldType(value: any): FieldType {
    var type = typeof (value);
    if (type == "string")
      return FieldType.String;
    else if (type == "number")
      return FieldType.Number;
    else if (type == "boolean")
      return FieldType.Boolean;
    else if (value instanceof Date)
      return FieldType.Date;
    else if (value.constructor == Object)
      return FieldType.StringList;
    return null;
  }

  /**
   * Gets the parameters of a custom field according with the fieldtype
   * @param name {string} the name of the field
   * @param value {any} the value of the field
   * @param string_list {string}
   * @param fieldtype {FieldType} the required fieldtype
   * @param translate {TranslateService} an instance of TranslateService
   * @returns the parameters of a custom field according with the field type
   */
  public static getCustomField(name: string, value: any, string_list: string, fieldtype: FieldType, translate: TranslateService = null): FieldConfig {
    var type;
    var inputType;
    var options;
    let do_translate;
    let is_json = false;
    if (fieldtype == FieldType.String) {
      type = "input";
      inputType = "text";
    }
    else if (fieldtype == FieldType.Number) {
      type = "input";
      inputType = "number";
    }
    else if (fieldtype == FieldType.Integer) {
      type = "input";
      inputType = "number";
    }
    else if (fieldtype == FieldType.Boolean) {
      type = "checkbox";
    }
    else if (fieldtype == FieldType.Date) {
      type = "date";
    }
    else if (fieldtype == FieldType.StringList) {
      type = "select";
      // in the rules the value is specified like this : {value:"LTE", values:"LTE;MTE;LT;MT;EQU"}
      // options must match this format to allow storing in rules
      if (value && value.constructor == Object) {
        string_list = value["values"];
        value = value["value"];
        options = string_list.split(";");
        is_json = true;
      }
      else {
        if (string_list == null)
          string_list = value;
        options = string_list.split(";");
      }
      if (translate)
        do_translate = true;
    }
    let label = (translate != null) ? translate.instant(name) : name;
    var field = {
      name: name,
      label: label,
      value: value,
      type: type,
      inputType: inputType,
      options: options,
      is_json: is_json,
      translate: do_translate,
    };
    return <FieldConfig>field;
  }

  /**
   * Gets the parameters of a custom field according with the type of the fieldtype
   * @param name  {string} the name of the field
   * @param value {any} the value of the field
   * @param translate {TranslateService} an instance of TranslateService
   * @returns the parameters of a custom field according with the type of the fieldtype
   */
  public static getCustomFieldForValue(name: string, value: any, translate: TranslateService = null): FieldConfig {
    return logicutils.getCustomField(name, value, null, logicutils.getFieldType(value), translate);
  }

  /**
   * Gets the generated tooltip with the required parameters
   * @param labels {string[]} an array of string with the required names of labels
   * @param values {string[]} an array of string with the required values
   * @returns the generated tooltip with the required parameters
   */
  public static getTooltip(labels: string[], values: string[]): string {
    let tooltip = "";
    tooltip += "<span><b>" + (values[0] + "").replace(" ", '\xa0') + " - " + (values[1] + "").replace(" ", '\xa0') + "</b></<span>" + "\r\n";
    for (var i = 2; i < labels.length; i++)
      tooltip += "<div>" + labels[i].padEnd(12, '\xa0') + " : <b>" + (values[i] + "").replace(" ", '\xa0') + "</b></<div>" + "\r\n";
    return tooltip;
  }

  /**
   * Group items with keys and insert them into a map
   * @param list {any[]} array of items to be inserted in the map
   * @param keyGetter {function} function that returns the key to be used with the map
   * @returns the map with the items and their associated keys
   */
  public static groupBy = function (list: any[], keyGetter) {
    const map = new Map();
    for (var item of list) {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection)
        map.set(key, [item]);
      else
        collection.push(item);
    }
    return map;
  }

  /**
   *
   * @param obj
   * @param single
   * @returns
   */
  public static formatObject(obj, single = false) {
    if (typeof obj != "object")
      return obj;
    if (!obj)
      return "";
    if (Array.isArray(obj))
      return logicutils.formatArray(obj);
    else
      return logicutils.formatJson(obj, single);
  }

  private static formatArray(array) {
    let result = []
    let single = array.length == 1;
    for (let item of array)
      result.push(logicutils.formatObject(item, single));
    return result.join(" - ");
  }

  private static formatJson(json, single) {
    let fragments = [];
    if (single) {
      for (let key of Object.keys(json))
        fragments.push(key + ": " + logicutils.formatObject(json[key]));
      return fragments.join(", ");
    } else {
      for (let key of Object.keys(json))
        fragments.push(logicutils.formatObject(json[key]));
      return fragments.join(" : ");
    }
  }

  // TODO : Create a compoenent for tables
  // TODO : Manage numbers

  /**
   * Sort a table in function of the event
   * @param event { function } the event to apply
   */
  public static sortTable(event) {
    let th = event.srcElement;
    let table = this.getParentTable(th);
    this.sortTableImpl(table, th.cellIndex)
  }

  private static getParentTable(element) {
    while (element) {
      element = element.parentNode;
      if (element.tagName.toLowerCase() === 'table')
        return element;
    }
    return null;
  }

  private static sortTableImpl(table, n) {
    var rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    // Make a loop that will continue until no switching has been done:
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      // Loop through all table rows (except the first, which contains table headers):
      for (i = 1; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        // Get the two elements you want to compare, one from current row and one from the next:
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        // Check if the two rows should switch place, based on the direction, asc or desc:
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
        // TODO : use this to manage numbers
        //if (Number(x.innerHTML) > Number(y.innerHTML)) {
        //  shouldSwitch = true;
        //  break;
        //}
      }
      if (shouldSwitch) {
        // If a switch has been marked, make the switch and mark that a switch has been done:
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        // If no switching has been done AND the direction is "asc", set the direction to "desc" and run the while loop again.
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  /**
   * Gets the list passed in parameter without duplicate item
   * @param origin_list {an[]} the list from which duplicate items should be removed
   * @param fields {string[]} the list of field names to compare for each item
   * @returns the list passed in parameter without duplicate item
   */
  public static getUniqueList(origin_list: any[], fields: string[]) {
    let previous = {};
    let filtered_list = [];
    // removes all the duplicated elements
    for (var i = 0; i < origin_list.length; i++) {
      let current = origin_list[i];
      if (!logicutils.areSame(previous, current, fields))
        filtered_list.splice(0, 0, current);
      previous = origin_list[i];
    }
    return filtered_list;
  }

  /**
   * Compare the equality of two items for each field names passed in parameter
   * @param previous {any} the previous item to compare
   * @param current {any} the current item to compare
   * @param field_names {string[]} the list of field names to compare for each item
   * @returns a boolean according to the equality of two items for each field names passed in parameter
   */
  private static areSame(previous: any, current: any, field_names: string[]): boolean {
    if (!previous)
      return false;
    for (var field_name of field_names)
      if (previous[field_name] != current[field_name])
        return false;
    return true;
  }

  /**
   * Initializes the data source of a datatable
   * @param list { any } the list containing the data of the datatable
   * @param paginator { MatPaginator } the paginator used in the datatable
   * @param sort { MatSort } the sorter used in the datatable
   * @return the initialized data source
   */
  public static getListDataSource(list: any[], paginator: MatPaginator, sort: MatSort, translate: TranslateService, sort_mapping = null): MatTableDataSource<any> {
    paginator._intl.itemsPerPageLabel = translate.instant('Items per page :');
    paginator._intl.firstPageLabel = translate.instant('First page');
    paginator._intl.previousPageLabel = translate.instant('Previous page');
    paginator._intl.nextPageLabel = translate.instant('Next page');
    paginator._intl.lastPageLabel = translate.instant('Last page');
    paginator._intl.getRangeLabel = function (page, pageSize, length) {
      const of_word = translate.instant('of');
      if (length === 0 || pageSize === 0)
        return '0 ' + of_word + ' ' + length;
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' ' + of_word + ' ' + length;
    };

    let data_source = new MatTableDataSource(list);
    data_source.paginator = paginator;
    data_source.sort = sort;
    if (sort_mapping) {
      data_source.sortingDataAccessor = (data, attribute) => {
        if (attribute in sort_mapping)
          return data[sort_mapping[attribute]];
        return data[attribute];
      };
    }
    return data_source;
  }

  /**
   * Filters a list and get the number of filtered items
   * @param list the list containing the items to count
   * @param filter the filter to apply to the list before getting the count
   */
  public static getCount(list: any[], filter): number {
    let filtered_list = list.filter(filter);
    let count: number = filtered_list ? filtered_list.length : 0;
    return count;
  }

  /**
   * Generate a CSV
   * @param translationService { TranslationService } an instance of translation service
   * @param headers { string } the header of the CSV
   * @param list { any [] } the list to used in the CSV
   * @param fields { string[] } the fields of the CSV
   * @returns the generated CSV
   */
  public static getCSV(translationService: TranslationService, headers: string[], list: any[], fields: string[]) {
    for (var i = 0; i < headers.length; i++)
      headers[i] = translationService.translate(headers[i]);
    let lines: string[] = [];
    lines.push(headers.join(';'));
    for (var event of list) {
      let cells: string[] = [];
      for (var field of fields) {
        let value = event[field] ? event[field] : "";
        cells.push(value);
      }
      let line = cells.join(";");
      lines.push(line);
    }
    let records = lines.join('\r\n');
    return records;
  }

  /**
   * Download file based on export type
   * @param export_kind { ExportKind } the type of export
   * @param data { any } the data to be inserted into the file
   * @param filename { any } the name of the file
   */
  public static doExportCollection(export_kind: ExportKind, data: any, file_name: string) {
    if (export_kind == ExportKind.Flat)
      this.downloadFileCsv(document, this.getGrossRecords(data), file_name);
    else if (export_kind == ExportKind.Splitted)
      this.downloadFileCsv(document, this.getSplittedRecords(data), file_name);
    else if (export_kind == ExportKind.Json)
      this.downloadFileJson(document, data, file_name);
  }

  /**
   * Gets gross records
   * @param data { any } the data to be recorded gross
   * @returns the data to recorded gross
   */
  private static getGrossRecords(data: any) {
    let records: string = "";
    for (var i = 0; i < data.length; i++)
      records += JSON.stringify(data[i]) + "\r\n";
    return records;
  }

  /**
   * Gets splitted records
   * @param data { any } the data to be recorded split
   * @returns the data to recorded split
   */
  private static getSplittedRecords(data: any) {

    let raw_values = []
    let records: string = "";
    let header = []
    for (var i = 0; i < data.length; i++) {
      let record = data[i];
      let values = this.getValues(header, "", record);
      raw_values.push(values);
    }

    // adds the header
    for (var head of header)
      records += head + ";";
    records += "\r\n";
    // adds all the records
    for (var raw of raw_values) {
      for (var head of header)
        records += raw[head] + ";";
      records += "\r\n";
    }
    return records;
  }

  /**
   * Gets the values of a record
   * @param header
   * @param prefix
   * @param record { any } the record in which to extract the values
   * @returns the values of a record
   */
  private static getValues(header, prefix: string, record: any): any {
    let values = {};
    for (var key in record) {
      let value = record[key];
      if (typeof value == "object") {
        // recurs to get the concrete entries
        let temp = this.getValues(header, prefix + key + ".", value);
        for (var temp_key in temp)
          values[temp_key] = temp[temp_key];
      } else {
        let real_key = prefix + key;
        // stores all the keys
        if (header.indexOf(real_key) < 0)
          header.push(real_key);
        // add a concrete entry
        values[real_key] = value
      }
    }
    return values;
  }

  /**
   * Download a file
   * @param document { Document } the entry point into the web page's content
   * @param data { any } the data to be inserted into the file
   * @param filename { any } the name of the file
   */
  public static downloadFile(document: Document, data: any, filename: string) {
    let blob = new Blob([new Uint8Array(data.data)]);
    this.download(document, blob, filename);
  }

  /**
   * Download a CSV file
   * @param document { Document } the entry point into the web page's content
   * @param data { any } the data to be inserted into the file
   * @param filename { any } the name of the file
   * @param extension { string } the file extension (".csv")
   */
  public static downloadFileCsv(document: Document, data: string, filename: string, extension: string = ".csv") {
    let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8' });
    this.download(document, blob, filename + extension);
  }

  /**
   * Download a Json file
   * @param document { Document } the entry point into the web page's content
   * @param data { any } the data to be inserted into the file
   * @param filename { any } the name of the file
   * @param extension { string } the file extension (".json")
   */
  public static downloadFileJson(document: Document, data: any, filename: string, extension: string = ".json") {
    let blob = new Blob([JSON.stringify(data)], { type: 'text/json' });
    this.download(document, blob, filename + extension);
  }

  /**
   * Create a download link, use it and remove it
   * @param document { Document } the entry point into the web page's content
   * @param blob { Blob } the data to be inserted into the file
   * @param filename { any } the name of the file
   */
  private static download(document: Document, blob: Blob, filename: string) {
    let link = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    //if Safari open in new window to save file with random filename.
    if (isSafariBrowser)
      link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Stream download

  /**
   * Export a stream
   * @param tokenService { TokenService } an instance of tokenservice
   * @param download_api { string } the URL of the download api
   * @param filename { string } the name of the file that contains the stream
   * @param parameters { any } the parameters of the body request
   * @param self the component that use this function
   * @param messageMethod the method that setup a message
   */
  public static exportStream(tokenService: TokenService, download_api: string, filename: string, parameters: any, self, messageMethod) {
    // Ponyfill WritableStream for Firefox
    if (!streamSaver.WritableStream)
      streamSaver.WritableStream = ponyfill.WritableStream;
    streamSaver.mitm = '/export/mitm.html';

    let fileStream = streamSaver.createWriteStream(filename);

    let start_time = Date.now();
    messageMethod(self, 'Download in progress', ErrorLevel.Warning);
    fetchStream(download_api, {
      method: "POST",
      body: JSON.stringify(parameters),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        'Authorization': 'Bearer ' + tokenService.getToken(),
      },
    }).then(response => this.readAllChunks(response.body, fileStream))
      .then(() => {
        if (AppConfig.front_settings.views.debug_query_durations) {
          let range = (parameters.filters.end_date.getTime() - parameters.filters.start_date.getTime()) / 1000 / 60 / 60;
          let range_string = (range > 23) ? (range / 24).toFixed(2) + " days" : range.toFixed(2) + " hours";
          let title = "Export States " + range_string;
          this.logDuration(title, start_time);
        }
        messageMethod(self, 'Download completed', ErrorLevel.Ok);
      })
  }

  private static readAllChunks(readableStream, fileStream) {
    const reader = readableStream.getReader();
    const writer = fileStream.getWriter();
    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          writer.close();
          return;
        }
        writer.write(value);
        return pump();
      });
    }
    return pump();
  }

  /**
   *
   * @param processes
   * @returns
   */
  public static getKnownProcesses(processes: any[]) {
    let known_processes = [];
    let calcul_process_count = 0;
    for (let process of processes) {
      let cmd = process.cmd;
      if (process.name.startsWith("python")) {
        if (cmd.includes("EngineLauncher"))
          process.label = "Engine Launcher";
        if (cmd.includes("WefindGetting"))
          process.label = "Engine Getting";
        else if (cmd.includes("WefindGeolocAggreg"))
          process.label = "Engine Aggregation";
        else if (cmd.includes("EngineLauncher"))
          process.label = "Engine Launcher";
        else if (cmd.includes("WefindRecover"))
          process.label = "Engine Recover";
        else if (cmd.includes("WefindGeolocCalcul")) {
          process.label = "Engine Calcul " + cmd.split(' ').pop().trim();
          calcul_process_count++;
        }
        else if (cmd.includes("WefindRule"))
          process.label = "Engine Rules";
        else if (cmd.includes("WefindClean"))
          process.label = "Engine Clean";
      }
      if (process.name.startsWith("node")) {
        if (cmd.includes("server-back.js"))
          process.label = "Server Back";
        else if (cmd.includes("server-public.js"))
          process.label = "Server Public";
        else if (cmd.includes("ng serve"))
          process.label = "Front Angular";
      }
      if (process.name.startsWith("ng serve")) {
        process.label = "Angular Front";
      }
      if (process.name.startsWith("mongod")) {
        process.label = "Mongo Database Server";
      }

      if (process.label)
        known_processes.push(process);
      known_processes.sort((a, b) => (a.label > b.label ? 1 : -1));
    }
    return { calcul_process_count, known_processes };
  }

  /**
   * Get the regex to check allowed langs in the application
   */
  public static AllowedLangs = /en|fr|de|no/;

  /**
   * Gets the chart lang options
   * @param translate {TranslationService} an instance of TranslationService
   * @returns the chart lang options
   */
  public static getChartLangOptions(translate: TranslationService): any {
    let lang = {
      contextButtonTitle: translate.translate("Chart context menu"),
      downloadCSV: translate.translate("Download CSV"),
      downloadJPEG: translate.translate("Download JPEG image"),
      downloadPDF: translate.translate("Download PDF document"),
      downloadPNG: translate.translate("Download PNG image"),
      downloadSVG: translate.translate("Download SVG vector image"),
      downloadXLS: translate.translate("Download XLS"),
      drillUpText: translate.translate("Back to {series.name}"),
      exitFullscreen: translate.translate("Exit from full screen"),
      loading: translate.translate("Loading..."),
      noData: translate.translate("No data to display"),
      printChart: translate.translate("Print chart"),
      resetZoom: translate.translate("Reset zoom"),
      resetZoomTitle: translate.translate("Reset zoom level 1:1"),
      viewData: translate.translate("View data table"),
      viewFullscreen: translate.translate("View in full screen"),
      shortMonths: translate.translate("short_months").split(","),
    };
    return lang;
  }

  /**
   * Gets a function which check if a mail match with the required criterias
   * @returns the function which check if a mail match with the required criterias
   */
  public static mailValidator(): ValidatorFn {
    return (control: AbstractControl): {
      [key: string]: any;
    } => {
      // if control is empty return no error
      if (!control.value)
        return null;
      /*    const regex = new RegExp('^[^\W][a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\@[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\.[a-zA-Z]{2,4}$', 'g');
            const valid = regex.test(control.value); */
      const valid = control.value.includes('@');
      return valid ? null : { invalidMail: true };
    };
  }

  /**
   * Gets a function which check if a password match with the required criterias
   * @returns the function which check if a password match with the required criterias
   */
  public static passwordValidator(): ValidatorFn {
    return (control: AbstractControl): {
      [key: string]: any;
    } => {
      // if control is empty return no error
      if (!control.value)
        return null;
      const regex = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{0,}$', 'g');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  /**
   * Custom validator to check that two fields match (used for password matching)
   * @param control_name {string} the name of the control containing the value to check
   * @param matching_control_name {string} the name of the control containing the value which must match
   */
  public static passwordMatchValidator(control_name: string, matching_control_name: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[control_name];
      const matching_control = formGroup.controls[matching_control_name];
      if (matching_control.errors && !matching_control.errors.mustMatch)
        return;
      // set error on matchingControl if validation fails
      if (control.value !== matching_control.value)
        matching_control.setErrors({ mustMatch: true });
      else
        matching_control.setErrors(null);
    };
  }

  /**
   * Logs the queries durations if the settings allow it
   * @param title the title of the message
   * @param start_time the start time of the query
   */
  public static logDuration(title: string, start_time: number, is_query: boolean = false) {
    if (AppConfig.front_settings && AppConfig.front_settings.views.debug_query_durations) {
      let end_time = Date.now();
      let duration = end_time - start_time;
      let ms = duration % 1000;
      let sec = Math.trunc((duration / 1000));
      console.info(" - " + (is_query ? "  " : "") + title.padEnd(is_query ? 30 : 50, " ") + " : " + sec.toString().padStart(6) + " s " + ms.toString().padStart(4) + " ms");
    }
  }

  /**
   * Gets the values of all the fields starting with the given prefix.
   * @param data the data containing the fields.
   * @param prefix the prefix of the fields to read.
   */
  public static GetValuesReport(data: any, prefix: string) {
    let report = "";
    for (var key of Object.keys(data))
      if (key.startsWith(prefix))
        report += "Type " + key.substring(prefix.length + 1).padStart(2, '0') + " : " + data[key] + "\r\n";
    return report;
  }
}