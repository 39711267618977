import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import ObjectID from 'bson-objectid';

import { BaseCrudUpdComponent } from '../../../../common/bases/base.crud.upd.component';
import { EquipmentUpdComponent } from '../../equipment/equipment-upd/equipment-upd.component';

import { AuthenticationService } from '../../../../services/authentication.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { EventService } from '../../../../services/event.service';
import { FloorService } from '../../../../services/floor.service';
import { SensorService } from '../../../../services/sensor.service';
import { TranslationService } from '../../../../services/translation.service';

import { dateutils } from '../../../../common/dateutils';
import { logicutils } from '../../../../common/logicutils';
import { navutils } from '../../../../common/navutils';
import { Mobility, MobilityText, SensorTypes, SensorTypesText } from '../../../../models/common';
import { workutils } from '../../../../common/workutils';


@Component({
  selector: 'app-sensor-upd',
  templateUrl: './sensor-upd.component.html'
})
export class SensorUpdComponent extends BaseCrudUpdComponent {

  public static openDialog(dialog: MatDialog, sensor_id: string, closed_callback) {
    navutils.openModalDialog(dialog, SensorUpdComponent, "60%", "500px", { sensor_id: sensor_id }, closed_callback);
  }

  public equipments: any[];
  public floors: any[];
  public types: any;
  public mobilities
  public reception_levels: any[];
  public temperature_internal = null;
  public show_temperature_internal = false;
  public use_temperature_internal = false;
  public battery_autonomy_formated = "";

  /**
   * Initialize a new instance of SensorUpdComponent
   */
  constructor(protected formBuilder: FormBuilder, protected router: Router,
    protected route: ActivatedRoute,
    protected authenticationService: AuthenticationService,
    private eventService: EventService,
    private sensorService: SensorService,
    private floorService: FloorService,
    private equipmentService: EquipmentService,
    private translation_service: TranslationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SensorUpdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sensor_id: any; popup_mode: boolean }
  ) {
    super(router, route, formBuilder, authenticationService)
    this.mobilities = logicutils.getEnumNames(Mobility, MobilityText, this.translation_service);
    this.types = logicutils.getEnumNamesString(SensorTypes, SensorTypesText, this.translation_service);
    this.reception_levels = workutils.getReceptionLevels();
  }

  protected getRecordId(): string {
    return this.data.sensor_id;
  }


  protected onGetTitle(): string {
    return 'Sensor';
  }

  /**
   * Initialize the form model
   * */
  protected initializeForm(): void {
    this.editForm = this.formBuilder.group({
      _id: [],
      equipment_data: [null],
      floor_data: [null],
      provider: [null],
      cloud_id: [''],
      name: ['', Validators.required],
      description: [''],
      type: [''],
      kit: [''],
      reception_level: 0,
      is_internal: false,
      no_locate: false,
      calculate_temperature_internal: false,
      collect_number: 0,
      mobility: [0, Validators.required],
      x: [null],
      y: [null],
      battery_percent: [{ value: null, disabled: true }],
      battery_update_time: [{ value: null, disabled: true }],
      battery_autonomy: [{ value: null, disabled: true }],
      event_delay: [{ value: null, disabled: true }],
    });

    if (!this.is_create) {
      this.editForm.controls['provider'].disable();
      this.editForm.controls['cloud_id'].disable();
      this.editForm.controls['type'].disable();
      this.editForm.controls['kit'].disable();
    }
  }

  /**
   * Gets the equipmment for the required id
   * */
  protected async fillData(record_id: string) {

    // fills the eqipement kinds for the lookup
    if (!this.is_create) {
      let data = await this.sensorService.getSensorWithEquipment(record_id).toPromise();
      this.setFormValues(data);
      this.show_temperature_internal = ((data["type"] == "temperature") || (data["type"] == "humidity"));
      this.use_temperature_internal = data["calculate_temperature_internal"];
      if (this.record_id && this.show_temperature_internal)
        await this.fillStatisticData(data);
    }
    this.equipments = await this.equipmentService.getEquipments().toPromise();
    this.floors = await this.floorService.getFloors().toPromise();
  }

  private async fillStatisticData(sensor) {
    let data = await this.eventService.getLastEvent(sensor.cloud_id).toPromise();
    if (!data)
      return;
    let update_time = data["event"]["state_update_time"];
    if (update_time)
      update_time = new Date(update_time).toLocaleString();
    this.temperature_internal = data["event"]["temperature_internal"] + " °C  (" + update_time + ")";
  }

  /**
   * Fills the data of the sensor on the form
   * @param sensor
   */
  setFormValues(sensor: any) {
    this.editForm.patchValue({
      _id: sensor._id,
      equipment_data: (sensor.equipment_data == null) ? null : sensor.equipment_data._id,
      floor_data: sensor.floor_data || null,
      provider: sensor.provider,
      cloud_id: sensor.cloud_id,
      name: sensor.name,
      description: sensor.description,
      status: sensor.status,
      type: sensor.type,
      kit: sensor.kit,
      reception_level: sensor.reception_level,
      is_internal: !!sensor.is_internal,
      no_locate: !!sensor.no_locate,
      calculate_temperature_internal: !!sensor.calculate_temperature_internal,
      collect_number: sensor.collect_number || null,
      mobility: sensor.mobility,
      x: sensor.x,
      y: sensor.y,
      battery_percent: sensor.battery_percent || null,
      battery_update_time: sensor.battery_update_time || null,
      battery_autonomy: sensor.battery_autonomy || null,
      event_delay: sensor.event_delay || null,
    });
    this.battery_autonomy_formated = dateutils.formatMonths(this.translation_service, sensor.battery_autonomy);
  }

  public createEquipment() {
    let equipment_id = new ObjectID();
    this.editForm.value.equipment_data = equipment_id.toString();
    this.onSubmit();
    EquipmentUpdComponent.openDialog(this.dialog, equipment_id.toString(), () => { });
  }

  /**
   * Submit the form and saves the Sensor in the database
   * */
  public async onSubmit() {
    this.submitted = true;

    // for fixed sensors some fields are mandatory
    if (this.form.mobility.value != Mobility.Mobile) {
      if (!this.form.floor_data.value)
        this.setError('floor_data', 'required');
      if (!this.form.x.value)
        this.setError('x', 'required');
      if (!this.form.y.value)
        this.setError('y', 'required');
    }
    // stop here if form is invalid
    if (this.editForm.invalid)
      return;

    let json = this.editForm.value;

    let data = await this.sensorService.checkNameExist(this.form._id.value, this.form.name.value).toPromise();
    if (data) {
      return this.setError('name', 'used');
    }
    if (this.is_create) {
      // this.sensorService.addSensor(json, this.returnList());
    } else
      this.sensorService.updSensor(json, null);
    this.close(true);
  }

  public close(response: boolean) {
    this.dialogRef.close(response);
  }

  public returnList() {
    this.router.navigate(['forms/sensors/list']);
  }

  public sendLedOn() {
    this.sensorService.sendLedOn(this.form.cloud_id.value, 20)
  }
}