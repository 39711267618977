import { TranslationService } from '../services/translation.service';
import { TimeFormat } from './enums';

export class dateutils {

  // number of ms in a day
  static day_ms = 86400000;
  // number of ms in an hour
  static hour_ms = 3600000;
  // number of ms in a minute
  static min_ms = 60000;
  // number of ms in a second
  static sec_ms = 1000;

  private static months: string[] = ['Janvier', 'F�vrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Ao�t', 'Septembre', 'Octobre', 'Novembre', 'D�cembre'];

  public static shortMonths: string[] = ["Jan", "F�v", "Mar", "Avr", "Mai", "Jun", "Jul", "Ao�", "Sep", "Oct", "Nov", "D�c"];

  public static GetMonthName(month: number): string {
    return this.months[month || 0];
  }

  public static GetShortMonthName(month: number): string {
    return this.shortMonths[month || 0];
  }

  /**
   * Gets the date at midnight.
   */
  public static GetMidnight(date: Date): Date {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }

  /**
  * format a date to like "2021-03-17 14_47"
  */
  static formatDate = function (date) {
    return dateutils.formatIsoString(date.toISOString())
  }

  static formatIsoString = function (date) {
    return date.substr(0, 16).replace("T", " ").replaceAll(".", "_");
  }

  static formatLocale(date_string: string, time_format: TimeFormat = TimeFormat.HH_MM): string {
    if (!date_string)
      return "";
    return dateutils.formatLocaleDateTime(new Date(date_string), time_format);
  }

  static formatLocaleDateTime(date: Date, time_format: TimeFormat = TimeFormat.HH_MM): string {
    if (!date)
      return "";
    let lang = navigator.language || navigator.languages[0];
    if (time_format == TimeFormat.None)
      return date.toLocaleDateString(/*[lang], { timeZone: "UTC" }*/);
    let format = {};
    switch (time_format) {
      case TimeFormat.HH_MM:
        format = { /*timeZone: "UTC",*/ hour: '2-digit', minute: '2-digit' };
        break;
      case TimeFormat.HH_MM_SS:
        format = {/* timeZone: "UTC",*/ hour: '2-digit', minute: '2-digit', second: '2-digit' };
        break;
      default:
    }
    return date.toLocaleDateString(/*[lang], { timeZone: "UTC" }*/) + " " + date.toLocaleTimeString(/*[lang]*/[], format);
  }

  static getMidnight(date: Date) {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  static addDays(date: Date, days: number) {
    // using "new Date(date.getTime() + (days * dateutils.day_ms))" doesn't take care of the daylight saving
    // if the reusulting exceed the las of the month, the month is incremented.
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static addHours(date: Date, hours: number) {
    return new Date(date.getTime() + (hours * dateutils.hour_ms));
  }

  static isOlderThan(date: Date, seconds: number) {
    return (new Date().getTime() - (seconds * dateutils.sec_ms)) >= date.getTime();
  }

  /**
   * Gets the difference between two dates in ms
   * @param date1 date 1
   * @param date2 data 2
   */
  public static Substract(date1: Date, date2: Date) {
    return date1.getTime() - date2.getTime();
  }

  /**
 * Gets if two dates difference is less than the specifed delay in milliseconds
 * @param {any} date1 the first date
 * @param {any} date2 the second date
 * @param {any} milliseconds the max delay to consider both dates are near
 */
  static areNear(date1: Date, date2: Date, milliseconds: number) {
    let diff = date1.getTime() - date2.getTime();
    return Math.abs(diff) <= milliseconds;
  }

  static getDuration(date1: Date, date2: Date) {
    return new Date(date1).getTime() - new Date(date2).getTime();
  }

  static formatDurationHHMM(milliseconds: number) {
    let full_seconds = milliseconds / 1000;
    let full_minutes = full_seconds / 60;
    let minutes = Math.floor(full_minutes % 60);
    let hours = Math.floor(full_minutes / 60);
    return (hours + "").padStart(2, "0") + ":" + (minutes + "").padStart(2, "0");
  }

  static formatDurationMMSSFromSec(full_seconds: number) {
    let seconds = Math.floor(full_seconds % 60);
    let minutes = Math.floor(full_seconds / 60);
    return (minutes + "").padStart(2, "0") + ":" + (seconds + "").padStart(2, "0");
  }

  static formatDurationMMSSMS(milliseconds: number) {
    let full_ms = milliseconds;
    let full_seconds = full_ms / 1000;
    let ms = Math.floor(full_ms % 1000);
    let seconds = Math.floor(full_seconds % 60);
    let minutes = Math.floor(full_seconds / 60);
    return (minutes + "").padStart(2, "0") + ":" + (seconds + "").padStart(2, "0") + ":" + (ms + "").padStart(3, "0");
  }

  /**
   * Formats the given number of monthes in years/monthes
   * @param translation_service
   * @param monthes
   */
  static formatMonths(translation_service: TranslationService, months: number) {
    if (!months || Number.isNaN(months))
      return "";
    return Math.trunc(months / 12) + " " + translation_service.translate("years") + " " + (months % 12) + " " + translation_service.translate("months")
  }

}