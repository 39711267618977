import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseCrudUpdComponent } from '../../../../common/bases/base.crud.upd.component';
import { logicutils } from '../../../../common/logicutils';
import { navutils } from '../../../../common/navutils';

import { AuthenticationService } from '../../../../services/authentication.service';
import { BuildingService } from '../../../../services/building.service';
import { FloorService } from '../../../../services/floor.service';
import { GatewayService } from '../../../../services/gateway.service';
import { FloorUpdComponent } from '../../floor/floor-upd/floor-upd.component';


@Component({
  selector: 'app-gateway-upd',
  templateUrl: './gateway-upd.component.html',
})
export class GatewayUpdComponent extends BaseCrudUpdComponent {

  public static openDialog(dialog: MatDialog, gateway_id: string, closed_callback) {
    navutils.openModalDialog(dialog, GatewayUpdComponent, "60%", "500px", { gateway_id: gateway_id }, closed_callback);
  }

  public floors: any;
  public buildings: any;

  public image: string;
  public safeImage: SafeResourceUrl;


  protected n_multi_floors: string = "";


  /**
   * Initialize a new instance of GatewayUpdComponent
   */
  constructor(protected formBuilder: FormBuilder, protected router: Router, protected route: ActivatedRoute,
    protected authenticationService: AuthenticationService,
    private gatewayService: GatewayService,
    private floorService: FloorService,
    private buildingService: BuildingService,
    public dialogRef: MatDialogRef<GatewayUpdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { gateway_id: any; popup_mode: boolean },
    public dialog: MatDialog,
  ) {
    super(router, route, formBuilder, authenticationService)
  }

  protected getRecordId(): string {
    return this.data.gateway_id;
  }

  public close(response: boolean) {
    this.dialogRef.close(response);
  }


  protected onGetTitle(): string {
    return 'Gateway';
  }

  /**
   * Initialize the form model
   * */
  protected initializeForm(): void {
    this.editForm = this.formBuilder.group({
      _id: [],
      name: [null, Validators.required],
      provider: [null],
      cloud_id: [null, Validators.required],
      is_bridge: false,
      ip_address: [null],
      port: [null],
      mac_address: [null],
      username: [null],
      password: [null],
      type: ['ccon', Validators.required],
      floor_data: [null, Validators.required],
      building_data: [null, Validators.required],
      n_rssi: [2.5],
      labels: this.formBuilder.group({
        kit: [null],
        name: [null, Validators.required],
        x: [0, Validators.required],
        y: [0, Validators.required],
        z: [0.3, Validators.required],
      }),

      connection: this.formBuilder.group({
        connected: [false],
        type: [''],
        update_time: [''],
        cellular_strength: [''],
      })
    });

    if (!this.is_create) {
      this.editForm.controls['provider'].disable();
      this.editForm.controls['cloud_id'].disable();
      this.editForm.controls['name'].disable();
      (this.editForm.controls["labels"] as FormGroup).controls['kit'].disable();
      this.editForm.controls['type'].disable();
      this.editForm.controls['connection'].disable();
      if (!this.hasRight("right_supervisor"))
        (this.editForm.controls["labels"] as FormGroup).controls['N'].disable();
    }
  }

  /**
   * Gets the flr for the required id
   * */
  protected async fillData(record_id: string) {

    // fills the buildings for the lookup
    this.buildings = await this.buildingService.getBuildings().toPromise();
    if (!this.is_create) {
      let data = await this.gatewayService.getGateway(record_id).toPromise();
      let building_id = data.building_data;
      // fills the floors for the lookup
      this.floors = await this.floorService.getFloorsForBuilding(building_id).toPromise();
      this.setFormValues(data);
      this.n_multi_floors = logicutils.GetValuesReport(data, "n_multi_floor");
    }
  }

  /**
   * Fills the data of the gateway on the form
   * @param gateway
   */
  setFormValues(gateway: any) {
    this.editForm.patchValue({
      _id: gateway._id,
      provider: gateway.provider,
      cloud_id: gateway.cloud_id,
      is_bridge: gateway.is_bridge,
      ip_address: gateway.ip_address,
      port: gateway.port,
      mac_address: gateway.mac_address,
      username: gateway.username,
      password: gateway.password,
      name: gateway.name,
      type: gateway.type,
      floor_data: gateway.floor_data,
      building_data: gateway.building_data,
      n_rssi: gateway.n_rssi,
      labels: {
        kit: gateway.labels.kit,
        name: gateway.labels.name,
        x: gateway.labels.x,
        y: gateway.labels.y,
        z: gateway.labels.z,
      }
    });

    const connection = this.formBuilder.group({
      connected: gateway.connection.connected,
      type: gateway.connection.type,
      update_time: gateway.connection.update_time,
      cellular_strength: gateway.connection.cellular_strength,
    })
    this.editForm.setControl('connection', connection);
  }

  /**
   * Submit the form and saves the record in the database
   * */
  public onSubmit() {
    this.submitted = true;

    // for XIG the ip address is manadatory
    if (this.form.is_bridge.value) {
      if (!this.form.ip_address.value)
        this.setError('ip_address', 'required');
    }

    // stop here if form is invalid]
    if (this.editForm.invalid)
      return;

    let json = this.editForm.value;
    if (this.is_create)
      this.gatewayService.addGateway(json, this.returnList());
    else
      this.gatewayService.updGateway(json, this.returnList());
    this.close(true);
  }

  public returnList() {
    this.router.navigate(['forms/gateways/list']);
  }

  public navigateFloor() {
    let floor_id = this.form.floor_data.value;
    if (!floor_id)
      return;
    this.close(false);
    FloorUpdComponent.openDialog(this.dialog, floor_id, () => { });
  }

  public navigateAddGateways() {
    let floor_id = this.form.floor_data.value;
    if (!floor_id)
      return;
    this.close(false);
    this.router.navigate(['wizards/add-gateways/', floor_id]);
  }

  public navigateLocationPicker() {
    let floor_id = this.form.floor_data.value;
    if (!floor_id)
      return;
    this.close(false);
    this.router.navigate(['wizards/location-picker', floor_id]);
  }

  public onChangeBuilding(event) {
    let building_id = event.target.value.split(":")[1].trim();
    this.floorService.getFloorsForBuilding(building_id).subscribe(data => {
      let floors = [];
      floors.push({ _id: null, name: "" });
      for (let record of data)
        floors.push(record);
      this.floors = floors;
    });
  }
}